$colors: (
  primary: #83b531,
  secondary: #E7B727,
  warning: #D04843,
  text: #0E1403,
  link: #83b531,
);

:root {
  --action-button-border: 2px solid transparent;
  --action-button-hover-border: 2px solid var(--primary-tint-66);
  --action-button-active-border: var(--action-button-hover-border);
}
