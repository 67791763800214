@import './mixins';
@import './variables';

:root {
  @include defineBaseColorVariables($colors);
  @include defineTintColorVariables($colors);
  @include defineShadeColorVariables($colors);
  @include defineZIndexVariables();
  --font-family: "WorkSans", sans-serif;
  --body-background-color: var(--text-tint-97);
  --bold-font-weight: 800;
  --inner-content-width: 1000px;
  --max-content-width: 1500px;
  --content-margin: 20px;
  --border-color: var(--text-tint-87);
}

@font-face {
  font-family: "WorkSans";
  src: url("../assets/fonts/work-sans/WorkSans-Regular.ttf");
}
@font-face {
  font-family: "WorkSans";
  font-weight: 800;
  src: url("../assets/fonts/work-sans/WorkSans-Bold.ttf");
}
@font-face {
  font-family: "WorkSans";
  src: url("../assets/fonts/work-sans/WorkSans-BoldItalic.ttf");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "WorkSans";
  src: url("../assets/fonts/work-sans/WorkSans-Italic.ttf");
  font-style: italic;
}

body {
  font-family: var(--font-family);
  background-color: var(--body-background-color);
  color: var(--text-color);
  font-size: 16px;
}

a {
  color: var(--primary-color);
}


sm-table {
  tfoot {
    td {
      background-color: var(--text-tint-87);
      font-weight: var(--bold-font-weight);
      padding: 9px 15px;

      @media print {
        padding: 0;
      }

      &:first-child {
        border-radius: 0 0 0 7px;
      }
      &:last-child {
        border-radius: 0 0 7px 0;
      }
    }
  }
}


button.stroked {
  &.stroked.size-md,
  &.size-md {
    border-width: 2px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

sm-loading {
  fill: var(--primary-color);
}
